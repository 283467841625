body,
html {
  cursor: none;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

header {
  color: black;
}

.styled-link {
  position: relative;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: #2480ed;
  text-decoration: none;
}

.styled-link,
.styled-link span {
  display: inline-block;
  transition: all .2s ease
}

.styled-link span {
  margin-left: 2px;
  color: rgba(0, 0, 0, .4)
}

.styled-link span svg {
  display: block
}

.styled-link:hover {
  color: #74b1fd
}

.styled-link:focus-visible {
  border-radius: 2px;
  padding: 0 2px;
  margin: 0 -2px;
  transition: none;
  outline: 2px solid var(--focus-color)
}

h1 {
  color: #000000;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.Bio {
  max-width: 600px;
  width: auto;
  padding: 0;
  margin: 50px auto 0;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  border: none;
  background: none;
  color: #656565;
  font-family: 'GT-Pressura-Mono-Regular', sans-serif;
}

.Bio h1 {
  text-align: left;
  margin: 0 0 10px;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: 'GT-Pressura-Mono-Regular';
  src: url('/fonts/GT-Pressura-Mono-Regular.ttf') format('truetype');
}

.custom-font {
  font-family: 'Samsies', sans-serif;
}